/* You can add global styles to this file, and also import other style files */

@use "@angular/material" as mat;
@import "src/sass/variables";
@import "src/sass/colours";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@include mat.core();


@include mat.core-theme($my-theme);
@include mat.button-theme($my-theme);
@include mat.datepicker-theme($my-theme);
@include mat.table-theme($my-theme);

body {
	font-family: var(--font-main), "Helvetica Neue", sans-serif;
	margin: 0;
	color: rgb(var(--text-default));
	background-color: rgb(var(--background-tone));
	height: fit-content;
}

* {
	box-sizing: border-box;
}



// Catch for Font Awesome icons rendering incorrectly on Server-Side Render
// - courtesy https://github.com/FortAwesome/angular-fontawesome/issues/48#issuecomment-660119343
fa-icon svg {
	display: inline-block;
	font-size: inherit;
	height: 1em;
}
fa-icon .fa-2x {
	height: 2em;
}
html {
	height: 100%;
	scroll-behavior: auto;
}



* {
	font-family: var(--font-main);
}

.dark-navy {
	color: rgb(var(--blue2));
}

.charcoal {
	color: #1a1d20;
}

.bright-yellow {
	color: rgb(var(--highlight-secondary)) !important;
}

.natural-grey {
	color: rgb(var(--grey3));
}

.bright-red {
	color: rgb(var(--highlight));
}

.f-white {
	color: rgb(var(--background-tone)) !important;
}

.f-black {
	color: rgb(var(--foreground-tone)) !important;
}

.f-no-decor {
	text-decoration: none;
}

a {
	text-decoration: none;
	cursor: pointer;
	color: rgb(var(--blue5));

	&:hover {
		color: rgb(var(--highlight));
	}
}

.pointer {
	cursor: pointer;
}



@layer base {
	ol {
		@apply list-decimal ml-5;
	}
	ul {
		padding: 0;
		margin-left: 20px;
		@apply list-disc;
	}
	h1 {
		@apply text-4xl font-bold;
	}
	h2 {
		@apply text-2xl font-semibold;
	}
	h3 {
		@apply text-xl font-semibold;
	}
	h4 {
		@apply text-lg font-semibold;
	}

    blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
        margin:revert;
    }
}


@keyframes autofill {
	0%,
	100% {
		color: #666;
		background: transparent;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-transition-delay: 9999s;
	transition-delay: 9999s;
}

.uppercase {
	text-transform: uppercase;
}

//font-sizes
.f-heading-1 {
	font-weight: 700;
	font-size: 38px;
}

.f-12 {
	font-size: 1.2em;
}

.f-heading-2 {
	font-weight: 700;
	font-size: 17px;

	@media screen and (min-width: 640px) {
		font-size: 24px;
	}
}

.f-body-1 {
	font-size: 24px;
}

.f-body-2 {
	font-size: 20px;
}

.f-subtitle {
	font-size: 17px;
}

.f-caption {
	font-weight: 700;
	font-size: 17px;
}

.f-38 {
	font-size: 38px;
}

.f-32 {
	font-size: 32px;
}

.f-24 {
	font-size: 24px;
}

.f-20 {
	font-size: 20px;
}

.f-16 {
	font-size: 16px;
}

.f-bold {
	font-weight: 700;
}

.f-semi-bold {
	font-weight: 600;
}

.f-light {
	font-weight: 500;
}

.f-lighter {
	font-weight: 200;
}

//buttons

.button-enabled {
	background-color: rgb(var(--dark-primary)) !important;
	color: rgb(var(--dark-primary-contrast)) !important;
	box-shadow: none !important;
	font-size: 14px;
	letter-spacing: 1.25px;
	transition: background-color 0.2s linear;
	cursor: pointer;

	&:hover {
		background-color: rgb(var(--dark-primary-lighter));
	}
}

.button-enabled-yellow {
	background-color: rgb(var(--highlight-secondary)) !important;
	border: 1px solid rgb(var(--highlight-secondary)) !important;
	font-weight: 700 !important;
	box-shadow: none !important;
	font-size: 14px;
	letter-spacing: 1.25px;
	transition: filter 0.2s linear;
	cursor: pointer;

	&:hover {
		filter: brightness(110%);
	}
}

// .button-enabled-yellow.tennis {
// 	color: rgb(var(--dark-primary)) !important;
// }

.button-enabled-yellow.outlined {
	background-color: transparent !important;
	border: 1px solid rgb(var(--highlight-secondary)) !important;
	color: rgb(var(--highlight-secondary)) !important;
	font-weight: 700 !important;
	box-shadow: none !important;
}

.button-hover {
	background-color: rgb(var(--highlight)) !important;
	color: rgb(var(--highlight-contrast)) !important;
	box-shadow: none !important;
}

.button-disabled {
	background-color: rgb(var(--highlight)) !important;
	color: rgb(var(--highlight-contrast)) !important;
	box-shadow: none !important;
}

.button-neg-enabled {
	background-color: rgba(var(--highlight) / 0.1) !important;
	color: rgb(var(--highlight)) !important;
	border: 1px solid rgb(var(--highlight)) !important;
	box-shadow: none !important;
	font-weight: 700 !important;
	font-size: 14px;
	letter-spacing: 1.25px;
	transition: background-color 0.2s linear;
}

.button-neg-enabled:hover {
	background-color: rgba(var(--highlight) / 0.2) !important;
}

.button-neg-enabled-yellow {
	background-color: rgb(var(--highlight-secondary) / 0.1) !important;
	color: rgb(var(--highlight-secondary)) !important;
	border: 1px solid rgb(var(--highlight-secondary)) !important;
	box-shadow: none !important;
	font-weight: 700 !important;
	font-size: 14px;
	letter-spacing: 1.25px;
	transition: background-color 0.2s linear;
}

.button-neg-enabled-yellow:hover {
	background-color: rgb(var(--highlight-secondary) / 0.2) !important;
}

.button-neg-disabled {
	background-color: rgb(var(--highlight-contrast)) !important;
	color: rgb(var(--highlight)) !important;
	border: 1px solid rgb(var(--highlight)) !important;
	box-shadow: none !important;
	font-size: 14px;
	letter-spacing: 1.25px;
	transition: background-color 0.2s linear;
}

.button-neg-enabled-trans {
	background-color: transparent !important;
	color: rgb(var(--highlight)) !important;
	border: 1px solid rgb(var(--highlight)) !important;
	box-shadow: none !important;
	font-weight: 700 !important;
	font-size: 14px;
	letter-spacing: 1.25px;
	transition: background-color 0.2s linear;
}

.button-neg-enabled-trans:hover {
	background-color: rgb(var(--highlight)) !important;
	color: rgb(var(--highlight-contrast)) !important;
}

.button-neg-enabled-trans-yellow {
	background-color: transparent !important;
	color: rgb(var(--highlight-secondary)) !important;
	border: 1px solid rgb(var(--highlight-secondary)) !important;
	box-shadow: none !important;
	font-weight: 700 !important;
	font-size: 14px;
	letter-spacing: 1.25px;
	transition: background-color 0.2s linear;
}

.button-neg-enabled-trans-yellow:hover {
	background-color: rgb(var(--highlight-secondary)) !important;
	color: rgb(var(--highlight-secondary-contrast)) !important;
}

.button-text {
	background-color: transparent !important;
	color: rgb(var(--text-default)) !important;
	box-shadow: none !important;
	font-weight: 600 !important;
}

//margins
.m-bottom-20 {
	margin-bottom: 20px !important;
}

.m-bottom-10 {
	margin-bottom: 10px;
}

.m-bottom-5 {
	margin-bottom: 5px !important;
}

.m-bottom-40 {
	margin-bottom: 40px;
}

.m-bottom-50 {
	margin-bottom: 40px;
}

.m-bottom-30 {
	margin-bottom: 40px;
}

.m-right-20 {
	margin-right: 20px;
}

.m-right-40 {
	margin-right: 40px;
}

.m-right-10 {
	margin-right: 20px;
}

.m-right-5 {
	margin-right: 5px;
}

.m-0 {
	margin: 0 !important;
}

.m-top-10 {
	margin-top: 10px;
}

.m-top-20 {
	margin-top: 20px;
}

.m-top-40 {
	margin-top: 40px;
}

//layout
.builder-default-layout {
	width: 100%;
	display: block;
	justify-content: center;
	padding: 5px 10px 5px 10px;
	max-width: 1110px;
	flex-direction: column;
	margin: 0 auto;
}

.builder-cover-bg {
	background-repeat: no-repeat;
	background-position: center center;
	background-attachment: fixed;
	background-size: cover;
	padding: 5px 10px 5px 10px;
}

//ellipsis
.ellipsis {
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
}

//ads
.articles-list .long-ad {
	display: flex;
	justify-content: center;
}

// met bet
.metabet-winnings {
	color: rgb(var(--blue5));
}

//
.desc.desk,
.desc.mob {
	// a {
	// 	color: #3434A9;
	// }
	a:hover {
		color: rgb(var(--highlight));
	}
}

.page-sub-heading {

	@media screen and (max-width: 959px) {
		padding: 0px 10px 0px 10px;
		a {
			color: rgb(var(--blue5));
		}
	}

	a {
		color: rgb(var(--blue5));
	}
}

//media queries
@media only screen and (max-width: 959px) {
	
	.mob-side-margin {
		margin-left: 10px;
		margin-right: 10px;
	}

	.mob-side-margin-20 {
		margin-left: 20px;
		margin-right: 20px;
	}

	.news-letter {
		height: 100% !important;
		max-width: 100% !important;
		border-radius: 0 !important;
		padding: 20px 40px 20px 40px;
	}

	.dimers-logo {
		max-width: 260px;
	}

	//font-sizes
	.f-heading-1 {
		font-size: 24px !important;
	}

	
	.article-type {
		font-size: 15px;
		line-height: initial;
	}

	.article-name {
		font-size: 15px;
		line-height: initial;
	}

	.content-title {
		line-height: 1.1em !important;
	}

	.title-header {
		font-size: 24px;
	}
}

//page builder
.builder-page {
	@media only screen and (max-width: 1136px) {
		.row {
			margin: 0 !important;
		}

		.card-mobile {
			padding: 10px;
			margin-right: 10px;
		}
	}
}

//customs
// override vision 6
#v6-join {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	text-align: center;
	p {
		margin-right: 20px;
		font-size: 0.9em;
		text-align: left;
		margin-top: 0;
	}
	.main-form {
		display: flex;
		.wfe_component {
			input {
				background: rgb(var(--background-tone));
				height: 39px;
				border: 1px solid rgb(var(--grey11));
				margin-right: 10px;
				margin-bottom: 10px;
				padding: 5px;

				@media only screen and (max-width: 393px) {
					width: 133px;
				}
			}
			button {
				display: flex;
				margin-bottom: 10px;
				background: rgb(var(--highlight-secondary));
				padding: 8px 16px 8px 15px;
				font-weight: 700;
				border: none;
				font-size: 16px;
				line-height: 1.5;
			}
		}
	}
}

//hidden welcome prop offer card on mobile
@media only screen and (max-width: $break-small) {
	app-book-offers {
		display: none;
	}
}

//Modify global breadcrumb style
.xng-breadcrumb-root {
	font-size: 12px;
	font-weight: 400;
	padding-bottom: 16px;
	.xng-breadcrumb-link {
		color: rgb(var(--text-default));
		&:hover {
			color: rgb(var(--highlight));
		}
	}
	.xng-breadcrumb-trail {
		font-weight: 600;
		text-decoration: underline;
	}
}
