@use "@angular/material" as mat;

:root {
	--background-tone: 255 255 255;
	--foreground-tone: 0 0 0;

	--overlay-tone: 0 0 0;
	
	--grey1: 237 240 243;
	--grey1-contrast: 0 0 0;
	--grey2: 128 128 128;
	--grey2-contrast: 0 0 0;
	--grey3: 151 151 151;
	--grey3-contrast: 0 0 0;
	--grey5: 122 122 141;
	--grey5-contrast: 0 0 0;
	--grey6: 72 90 114;
	--grey6-contrast: 255 255 255;
	--grey7: 78 78 78;
	--grey7-contrast: 255 255 255;
	--grey8: 248 248 248;
	--grey8-contrast: 0 0 0;
	--grey9: 216 216 216;
	--grey9-contrast: 0 0 0;
	--grey10: 99 113 135;
	--grey10-contrast: 255 255 255;
	--grey11: 224 224 224;
	--grey11-contrast: 0 0 0;
	--grey12: 231 231 231;
	--grey12-contrast: 0 0 0;
	
	--orange: 252 170 53;
	--orange-contrast: 0 0 0;
	
	--blue2: 16 13 44;
	--blue2-contrast: 255 255 255;
	
	--blue3: 1 132 232;
	--blue3-contrast: 255 255 255;
	
	--blue4: 44 38 72;
	--blue4-contrast: 255 255 255;
	
	--blue5: 0 0 238;
	--blue5-contrast: 255 255 255;
	
	--green1: 7 132 66;
	--green1-contrast: 255 255 255;
	
	--green3: 0 136 57;
	--green3-contrast: 255 255 255;

	--green4: 220 252 231;
	--green4-hover: 187 247 208;
	--green4-selected: 74 222 128;
	--green4-contrast: 0 0 0;
	
	--red1: 235 12 0;
	--red1-contrast: 255 255 255;

	--red2: 254 226 226;
	--red2-hover: 254 202 202;
	--red2-selected: 248 113 113;
	--red2-contrast: 0 0 0;
	
	--pink2: 255 141 141;
	--pink2-contrast: 0 0 0;
	
	--purple1: 111 11 255;
	--purple1-contrast: 255 255 255;

	--purple2: 109 40 217;
	--purple2-contrast: 255 255 255;
	
	--yellow1: 239 227 0;
	--yellow1-contrast: 0 0 0;

	--yellow2: 214 158 46;
	--yellow2-contrast: 0 0 0;

	--yellow3: 254 249 195;
	--yellow3-hover: 254 240 138;
	--yellow3-selected: 250 204 21;
	--yellow3-contrast: 0 0 0;
	
	--text-default: 16 13 44;

	--article-text-default: 33 33 33;
	
	
	--highlight: 29 78 216;
	--highlight-contrast: 255 255 255;

	--highlight-secondary: 239 227 0;
	--highlight-secondary-contrast: 0 0 0;

	--dark-primary: 16 13 44;
	--dark-primary-contrast: 255 255 255;

	--dark-secondary: 44 38 72;
	--dark-secondary-contrast: 255 255 255;
	
	
	--shadow1: 224 224 224;
	
	--border1: 216 216 216;
	--border2: 151 151 151;
	--border3: 78 78 78;
	--border4: 237 240 243;
	--border5: 231 231 231;
	--border6: 72 90 114;
	
	
	
	
	--body-text2: 98 112 135;
	--body-text3: 122 138 159;
	--body-text4: 192 192 192;
	--button1: 248 248 248;
	--button1-contrast: 0 0 0;
	--button2: 253 253 253;
	--button2-contrast: 0 0 0;
	--button3: 224 224 224;
	--button3-contrast: 0 0 0;
	--upcoming-blue: 16 13 44;
	--upcoming-blue-contrast: 255 255 255;
	--live-green: 0 128 79;
	--live-green-contrast: 255 255 255;
	--final-red: 235 12 0;
	--final-red-contrast: 255 255 255;
	--paused-grey: 98 112 135;
	--paused-grey-contrast: 255 255 255;
	--draw-grey: 124 138 167;
	--shortlist-gold: 248 214 78;
	
	--left-team-colour: 223 70 97;
	--right-team-colour: 16 13 44;

	--blue2-lighter: 30 25 83;

	--dark-primary-lighter: 30 25 83;
	
	--highlight-lighter: 66 109 230;
	--block-toggle-selected: 16 13 44;
	--block-toggle-selected-contrast: 255 255 255;
	--prob-progression-background: 66 109 230;

	--article-category-label: 111 11 255;

	--highlight-darker: 97 131 228;


	// @media screen and (prefers-color-scheme: dark) {
	// 	--background-tone: 0 0 0;
	// 	--foreground-tone: 255 255 255;

	// 	--text-default: 255 255 255;
	// 	--grey1: 16 16 16;
	// 	--grey1-contrast: 255 255 255;

	// 	--button1: 8 8 8;
	// 	--button1-contrast: 255 255 255;

	// 	--left-team-colour: 223 70 97;
	// 	--right-team-colour: 255 255 255;
	// }
}



$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme((
	color: (
		primary: $my-primary,
		accent: $my-accent,
	)
));
